import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/css3-media-queries",
  "date": "2014-04-04",
  "title": "CSS3 MEDIA QUERIES",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "When you are designing your responisve layout for mobile devices, CSS3 media queries are an essential component on your stylesheets. Let’s discuss how they work and what min-with and max-with work together."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Min-Width`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media only screen and (min-width: 330px)  {...}
`}</code></pre>
    <p>{`Here's what that actually means:`}</p>
    <p><strong parentName="p">{`If `}{`[device width]`}{` is greater than or equal to: specified value, then do {...}`}</strong></p>
    <p>{`So if the actual "device width" is 310px this condition will return false.`}</p>
    <p><strong parentName="p">{`If 310px is greater than or equal to 330px, then do {...}`}</strong></p>
    <p>{`The following CSS will apply if the viewing area is greater than 900px.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (min-width: 900px) {
  .class {
    background: #666;
  }
}
`}</code></pre>
    <h2>{`Max Width`}</h2>
    <p>{`The following CSS will apply if the viewing area is smaller than 600px.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (max-width: 600px) {
  .class {
    background: #ccc;
  }
}
`}</code></pre>
    <p>{`If you want to link to a separate stylesheet, put the following line of code in between the `}<inlineCode parentName="p">{`<head> `}</inlineCode>{`tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link rel="stylesheet" media="screen and (max-width: 600px)" href="small.css" />
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media only screen and (max-width: 330px)  {...}
`}</code></pre>
    <p>{`Translates to:`}</p>
    <p><strong parentName="p">{`If device width is less than or equal to: specified value, then do {...}`}</strong></p>
    <p>{`So based on this second example, if the "device width" is 320px the condition is true:`}</p>
    <p><strong parentName="p">{`If 320px is less than or equal to 330px, then do {...}`}</strong></p>
    <p>{`It becomes false if you pass any value less than 320.`}</p>
    <p>{`This means that `}<strong parentName="p">{`max-width`}</strong>{` is the maximum width at which these styles will be shown. A screen wider than the specified number will not use the styles associated with that rule. Similarly, `}<strong parentName="p">{`min-width`}</strong>{` is the minimum width at which these styles will be shown. A screen narrower than the specified number will not use the styles associated with that rule.`}</p>
    <h3>{`Multiple Media Queries`}</h3>
    <p>{`You can combine multiple media queries. The following code will apply if the viewing area is between 600px and 900px.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (min-width: 600px) and (max-width: 900px) {
  .class {
    background: #333;
  }
}
`}</code></pre>
    <h2>{`Commonly used Media Queries in Email`}</h2>
    <p>{`From reading many industry blogs and even blog posts that I have written myself, we generally provide samples with just two conditions: `}<strong parentName="p">{`screen`}</strong>{` and a`}<strong parentName="p">{`max-width`}</strong>{`. For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media only screen and (max-width: 640px)  {...}
`}</code></pre>
    <p>{`This statement would include a screen (monitor or device) AND any width less than 640px. Confusing right? Especially considering that in our recent responsive email templates, we use a combination of two breakpoints. `}</p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media only screen and (max-width: 640px)  {...}
@media only screen and (max-width: 479px)  {...}
`}</code></pre>
    <p>{`The second media query actually trumps the first due to standard CSS inheritance rules. By rights, the first triggers any device with a width less than 640px and the second triggers any device with a width less than 479px. So if you truly want to trump the first, you need to make sure that you overwrite all CSS properties under the second condition.`}</p>
    <p>{`A more succinct approach would be to do the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media only screen and (min-width:480px) and (max-width: 640px)  {...}
@media only screen and (max-width: 479px)  {...}
`}</code></pre>
    <p>{`The only difference is that we added a `}<strong parentName="p">{`min-width`}</strong>{` condition to the first media query. This now separates the two queries so that one doesn't necessarily have to trump the other. The first is now saying anything greater than 480px and less than 640px, the other is simply stating anything less than 479px.`}</p>
    <p>{`The `}<strong parentName="p">{`device width`}</strong>{` that is returned is based on the orientation of the device. It's `}<strong parentName="p">{`not`}</strong>{` based on the highest 'possible dimension' that the device can support in any orientation. Again, the term `}<strong parentName="p">{`'max-width'`}</strong>{` is what led to my initial confusion here. Some devices return a different value depending on orientation and/or zoom level and others return the same value. Either way, there's only one 'width' value returned at any given time, it's not based on a range of values.`}</p>
    <h3>{`Device Width`}</h3>
    <p>{`The following code will apply if the max-device-width is 480px (eg. iPhone display). Note: max-device-width means the actual resolution of the device and max-width means the viewing area resolution.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media screen and (max-device-width: 480px) {
  .class {
    background: #000;
  }
}
`}</code></pre>
    <h3>{`For iPhone 4`}</h3>
    <p>{`The following stylesheet is specifically for iPhone 4.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`<link rel="stylesheet" media="only screen and (-webkit-min-device-pixel-ratio: 2)" type="text/css" href="iphone4.css" />
`}</code></pre>
    <h3>{`For iPad`}</h3>
    <p>{`You can also use media query to detect orientation (portrait or landscapse) on the iPad.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`<link rel="stylesheet" media="all and (orientation:portrait)" href="portrait.css"> 
<link rel="stylesheet" media="all and (orientation:landscape)" href="landscape.css">
`}</code></pre>
    <h2>{`Media Queries for Internet Explorer`}</h2>
    <p>{`Unfortunately, media query is not supported in Internet Explorer 8 or older. You can use Javascript to hack around.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      